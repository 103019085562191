import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from '@emotion/styled';
import { Helmet } from "react-helmet"

import Header from "../components/Header"
import Footer from "../components/Footer"
import recordImage from "../assets/recordImage.jpg"
import ArtistList from "../components/ArtistList";

const Background = styled.div`
  background-image: url('${recordImage}');
  background-size: cover;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const HeaderContainer = styled.div`
  margin: 0 16px;
`
const TextContainer = styled.div`
  text-align: center;
  padding:16px;
`
const DummyContainer = styled.div`
  height: 100px;
`
const P = styled.p`
  font-size: 14px;
  margin: 8px 0;
`
const Content = styled.div`
  padding: 32px 16px;
`
const FooterContainer = styled.div`
  margin: 0 16px;
`

export default function Page() {
  const data = useStaticQuery(graphql`
    query {
      allArtistsJson {
        edges {
          node {
            name
            twitterId
            contents {
              title
            }
          }
        }
      }
    }
  `)
  const { edges } = data.allArtistsJson;
  return (
    <div>
      <Helmet
        htmlAttributes={{
          lang: 'ja',
        }}
      >
        <meta charSet="utf-8" />
        <title>あの人のおすすめ音楽 - RecoMusic</title>
        <link rel="canonical" href="https://music.search-maps.com" />
        <meta name="description" content="あの有名アーティストが聞いている音楽は？SNSでおすすめしている音楽をまとめました！みなさんの音楽ライフがよりよいものになりますように。" />
      </Helmet>

      <Background>
        <HeaderContainer>
          <Header isLink={false} />
        </HeaderContainer>
        <TextContainer>
          <h1>Dive into Music</h1>
          <P>あなたはどうやって新しい音楽を探しますか？</P>
          <P>このウェブサイトでは、アーティストがSNSでおすすめしている音楽をまとめました。</P>
          <P>みなさんの音楽ライフがよりよいものになりますように。</P>
        </TextContainer>
        <DummyContainer />
      </Background>

      <Content>
        <ArtistList edges={edges} title="アーティスト一覧" />
      </Content>

      <FooterContainer>
        <Footer />
      </FooterContainer>

    </div>
  );
}
